<template>
  <!--引导 选择 角色页-->
  <div class="select-box">
    <div class="top">
      <img src="@/assets/images/select-top.png" />
    </div>
    <div class="select">
      <div class="item" @click="go('my-user-authentication')">
        <div class="left">
          <img src="@/assets/images/gr.png" />
        </div>
        <div class="right">
          <div class="title">我是工人,我来找活</div>
<!--          <div class="desc">我要去找活干</div>-->
        </div>
      </div>
      <div class="item"  @click="go('my-authentication')">
        <div class="left">
          <img src="@/assets/images/lb.png" />
        </div>
        <div class="right">
          <div class="title">我是项目经理,我来招工</div>
<!--          <div class="desc">我要去找工人做事</div>-->
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import store from '../../store'
export default {

  components: {
  },
  data() {
    return {
      index: 0,
      name:undefined,
      sex:undefined,
      phone:undefined,
    };
  },
  mounted() {
    // let user = JSON.parse(`${store.state.user}`)
    // console.info("load user",user)
    // if(user.phone){
    //   this.go("home")
    // }
  },
  methods: {
    go(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less" scoped>
  body{
    background: #F0F5FD;
  }
  .select-box{
    margin: auto;
    text-align: center;
  }
  .top{
    width: 100%;
    height: 240px;
    img{
      width: 100%;
      height: 240px;
    }
  }
  .select{
    text-align: center;
    .item{
      margin: auto;
      margin-bottom: 18px;
      margin-top: 6px;
      width: 85%;
      height: 106px;
      background: #FAFBFF;
      box-shadow: 0px 3px 6px #D8DAEA;
      opacity: 1;
      border-radius: 10px;
      display: flex;
      .left{
        width: 30%;
        padding-top: 25px;
        padding-left: 10px;
        img{
          width: 91px;
          height: 78px;
        }
      }
      .right{
        width: 70%;
        padding-top: 38px;
        text-align: left;
        padding-left: 12px;
        .title{
          font-size: 21px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #212121;
          opacity: 1;
        }
        .desc{
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #8B8D90;
          opacity: 1;
        }
      }
    }
  }


</style>
<style>
.home .c-nav-bar{
  margin-bottom: 0px;
}
</style>